.container {
  background-color: #f8f8f8;
  height: 100%;
}
.container .content {
  background-color: #f8f8f8;
  padding-bottom: 1rem;
}
.container .head_right {
  color: #ffffff;
  font-size: 14px;
}
.user_msg {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  font-family: PingFang SC;
}
.user_msg .content-top {
  margin: 0 0.4rem;
}
.user_msg .content-top .all_cout {
  display: flex;
  padding: 0.24rem 0;
}
.user_msg .content-top .all_cout .con_left {
  color: #5899f6;
  font-size: 0.3rem;
  font-weight: 500;
}
.user_msg .content-top .all_cout .con_right {
  color: #5899f6;
  font-size: 0.3rem;
  font-weight: 500;
  margin-left: 0.28rem;
}
.user_msg .content1 {
  padding: 0.12rem 0.4rem 0.38rem 0.4rem;
}
.user_msg .content1 .list {
  display: flex;
  align-items: center;
  padding: 0.08rem 0;
}
.user_msg .content1 .list .con_left {
  color: #333333;
  font-size: 0.3rem;
  font-weight: 500;
  text-align: left;
}
.user_msg .content1 .list .con_right {
  color: #666666;
  font-size: 0.28rem;
  margin-left: 0.28rem;
  display: flex;
}
.user_msg .content1 .list .con_right .right {
  margin-left: 1.5rem;
}
.user_msg .content1 .list .con_right .right-jingxin {
  color: #13ad8f;
}
.user_msg .content1 .list .con_right .right-shenhe {
  color: #d98181;
}
.user_msg .content1 .button {
  margin-top: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_msg .content1 .button .but_left {
  width: 2.84rem;
  height: 0.75rem;
  line-height: 0.75rem;
  background-color: #5899f6;
  border-radius: 0.08rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.28rem;
}
.user_msg .content1 .button .but_right {
  width: 2.84rem;
  height: 0.75rem;
  line-height: 0.75rem;
  margin-left: 0.38rem;
  background-color: #d98181;
  border-radius: 0.08rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.28rem;
}
.user_msg .content1 .button .but_right1 {
  width: 2.84rem;
  height: 0.75rem;
  line-height: 0.75rem;
  margin-left: 0.38rem;
  background-color: #13ad8f;
  border-radius: 0.08rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.28rem;
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 0;
}
.bottom .button {
  margin: 0.14rem 0.3rem 0 0.28rem;
  padding: 0.22rem 2.82rem;
  background-color: #5899f6;
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.12rem;
  font-weight: 500;
}
.tan {
  width: 5.6rem;
  height: 3.6rem;
  background-image: url(../../assets/imgs/tan_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 3.6rem;
}
.tan .tan_no_txt1 {
  padding: 1.4rem 0.82rem 0 0.72rem;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
  color: #333333;
}
.tan .tan_no_txt2 {
  margin-top: 0.05rem;
  color: #333333;
  font-size: 0.24rem;
  transform: scale(0.75);
  font-weight: 500;
  color: #666666;
  text-align: center;
}
.tan .tan_no_txt2 .right {
  color: #d98181;
}
.tan .tan_no_button {
  margin: 0 1.02rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.tan .tan_no_button .yes {
  color: #5899f6;
  padding: 0.08rem 0.32rem;
  border: 1px solid #5899f6;
  border-radius: 0.16rem;
  background-color: #ffffff;
}
.tan .tan_no_button .no {
  color: #ffffff;
  padding: 0.1rem 0.22rem 0.08rem 0.24rem;
  border-radius: 0.16rem;
  background-color: #5899f6;
}
